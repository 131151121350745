import { useMemo } from "react";
import { useTranslation } from "next-i18next";

import { GtmEvent } from "@sellernote/_shared/src/types/common/common";

import useAlibabaSourcing from "../../../hooks/common/useAlibabaSourcing";

type Menu = {
  label: string;
  path?: string;
  width?: string;
  submenuList?: Submenu[];
};

type Submenu = {
  label: string;
  labelForFooter: string;
  desc?: React.ReactNode;
  badges?: string[];
  path: string;
  pathHash?: string;
  Icon?: React.ReactNode;
  gtmEvent?: GtmEvent;
};

export type { Menu, Submenu };

/**
 * 메뉴 목록을 반환
 */
export default function useMenuList() {
  const { t } = useTranslation(["containers"]);

  const { alibabaUrl, alibabaGtmEvent } = useAlibabaSourcing();

  const menuList = useMemo(() => {
    const HOME_MENU: Menu = {
      label: t("containers:Layout.홈"),
      width: "64px",
      submenuList: [
        {
          label: t("containers:Layout.홈"),
          labelForFooter: t("containers:Layout.홈"),
          path: "/",
        },
      ],
    };

    const COMPANY_MENU: Menu = {
      label: t("containers:Layout.회사소개"),
      width: "80px",
      submenuList: [
        {
          label: t("containers:Layout.회사소개"),
          labelForFooter: t("containers:Layout.회사소개"),
          path: "/company",
        },
      ],
    };

    const SERVICE_FORWARDING_MENU: Menu = {
      label: `${t("containers:Layout.서비스")}(Forwarding)`,
      width: "128px",
      submenuList: [
        {
          label: t("containers:Layout.해상운송"),
          labelForFooter: t("containers:Layout.해상운송"),
          badges: ["FCL", "LCL"],
          desc: t("containers:Layout.수출입_해상운임_견적의뢰"),
          path: "/forwarding/ocean",
        },
        {
          label: t("containers:Layout.항공운송"),
          badges: ["AIR"],
          labelForFooter: t("containers:Layout.항공운송"),
          desc: t("containers:Layout.수출입_항공운임_견적의뢰"),
          path: "/forwarding/air",
        },
        {
          label: t("containers:Layout.스케줄_조회"),
          badges: ["ALL"],
          labelForFooter: t("containers:Layout.스케줄_조회"),
          desc: t("containers:Layout.해운/항공사별_스케줄_조회"),
          path: "/forwarding/schedule",
        },
      ],
    };

    const SERVICE_FULFILLMENT_MENU: Menu = {
      label: `${t("containers:Layout.서비스")}(Fulfillment)`,
      width: "116px",
      submenuList: [
        {
          label: t("containers:Layout.국내_출고"),
          badges: ["B2C", "B2B"],
          labelForFooter: t("containers:Layout.국내_출고"),
          desc: t("containers:Layout.스마트스토어__쿠팡__카페24_등_국내출고"),
          path: `/fulfillment/domestic`,
        },
        {
          label: t("containers:Layout.해외_출고"),
          badges: [`B2C`, `B2B`],
          labelForFooter: t("containers:Layout.해외_출고"),
          desc: t("containers:Layout.Shopify__Cafe24_Global_등_해외출고"),
          path: `/fulfillment/overseas`,
        },
        {
          label: t("containers:Layout.이벤트_출고"),
          badges: [`B2C`, `B2B`],
          labelForFooter: t("containers:Layout.이벤트_출고"),
          desc: t(
            "containers:Layout.와디즈__텀블벅__홈쇼핑_등_단발성_대량출고"
          ),
          path: `/fulfillment/event`,
        },
      ],
    };

    const SERVICE_TRADE_MENU: Menu = {
      label: `${t("containers:Layout.서비스")}(Trade Management)`,
      width: "180px",
      submenuList: [
        {
          label: t("containers:Layout.대금결제_T/T_"),
          labelForFooter: t("containers:Layout.대금결제_T/T_"),
          desc: t("containers:Layout.은행_방문없이_온라인_무역대금_이체"),
          path: `/tt`,
        },
        {
          label: t("containers:Layout.상품소싱_쉽다_X_알리바바닷컴_"),
          labelForFooter: t("containers:Layout.상품소싱_쉽다_X_알리바바닷컴_"),
          desc: t("containers:Layout.상품소싱하고_물류비_할인쿠폰_수령"),
          path: alibabaUrl,
          gtmEvent: alibabaGtmEvent,
        },
      ],
    };

    const TECH_MENU: Menu = {
      label: t("containers:Layout.기술"),
      width: "64px",
      submenuList: [
        {
          label: t("containers:Layout.오픈_API"),
          labelForFooter: t("containers:Layout.오픈_API"),
          desc: t("containers:Layout.발주__운송_등_Open_API_문서_제공"),
          path: "https://developers.ship-da.com/",
        },
      ],
    };

    const SUPPORT_MENU: Menu = {
      label: t("containers:Layout.고객지원"),
      width: "80px",
      submenuList: [
        {
          label: `${t("containers:Layout.가이드")}(Guide)`,
          labelForFooter: t("containers:Layout.가이드"),
          desc: t("containers:Layout.이용방법__용어_및_프로세스__FAQ"),
          path: "/support/guide",
        },
        {
          label: t("containers:Layout.공지사항"),
          labelForFooter: t("containers:Layout.공지사항"),
          desc: t("containers:Layout.서비스/정책_주요사항_안내"),
          path: "/support/notice",
        },
        {
          label: t("containers:Layout.동영상"),
          labelForFooter: t("containers:Layout.동영상"),
          desc: t("containers:Layout.서비스_및_상세_프로세스_영상"),
          path: "/support/onlinelecture",
        },
      ],
    };

    const BLOG_MENU: Menu = {
      label: t("containers:Layout.블로그"),
      width: "64px",
      submenuList: [
        {
          label: t("containers:Layout.블로그"),
          labelForFooter: t("containers:Layout.블로그"),
          path: "https://www.ship-da.com/blog",
        },
      ],
    };

    return [
      HOME_MENU,
      COMPANY_MENU,
      SERVICE_FORWARDING_MENU,
      SERVICE_FULFILLMENT_MENU,
      SERVICE_TRADE_MENU,
      TECH_MENU,
      SUPPORT_MENU,
      BLOG_MENU,
    ];
  }, [alibabaGtmEvent, alibabaUrl, t]);

  return menuList;
}
