import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import Styled from "./index.styles";

/**
 * i18n 기본 설정
 */
const defaultOptions = {
  returnNull: true,
  returnEmptyString: true,
  keySeparator: ".",
  nsSeparator: ":",
  saveMissing: false,
  saveMissingTo: "fallback",
  missingKeyHandler: undefined,
};

/**
 * i18n 키 보이기 설정
 */
const keyDisplayOptions = {
  returnNull: false,
  returnEmptyString: false,
  keySeparator: false,
  nsSeparator: false,
  saveMissing: true,
  saveMissingTo: "all",
  missingKeyHandler: (lng: readonly string[], ns: string, key: string) => key,
};

/**
 * i18n key 토글 버튼 (프로덕션 환경에서는 노출되지 않도록 주의)
 */
export default function ToggleI18nKey({ needsBlackText = false }) {
  const [displaysKey, setDisplaysKey] = useState(false);

  const router = useRouter();

  const { i18n } = useTranslation();

  const toggleI18nKey = () => {
    const newDisplaysKey = !displaysKey;

    setDisplaysKey(newDisplaysKey);

    // reload 시 상태가 초기화 되기 때문에 localStorage에 저장
    localStorage.setItem("displaysKey", newDisplaysKey.toString());

    router.reload();
  };

  // 번역된 문구와 i18n Key 중 하나를 화면에 표시
  useEffect(() => {
    const savedDisplaysKey = localStorage.getItem("displaysKey") === "true";

    setDisplaysKey(savedDisplaysKey);

    if (savedDisplaysKey) {
      Object.assign(i18n.options, keyDisplayOptions);
      return;
    }

    Object.assign(i18n.options, defaultOptions);
  }, [i18n]);

  return (
    <Styled.toggleI18nKey
      onClick={toggleI18nKey}
      needsBlackText={needsBlackText}
    >
      Toggle Key
    </Styled.toggleI18nKey>
  );
}
