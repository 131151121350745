/* eslint-disable @next/next/no-img-element */
import { useEffect } from "react";
import { useAtom } from "jotai";
import Image from "next/image";
import { useRouter } from "next/router";

import { ShipdaCurrentLanguage } from "@sellernote/_shared/src/i18n/i18nForShipda";
import { CHAT_BUTTON_DEFAULT_BOTTOM_POSITION } from "@sellernote/_shared/src/services/chat/constants";
import initChatButtonUI from "@sellernote/_shared/src/services/chat/initChatButtonUI";
import openChatModal from "@sellernote/_shared/src/services/chat/openChatModal";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import { COMMON_LAYOUT_ATOMS } from "@sellernote/shipda-kr/src/jotaiStates/common/layout";

import Styled from "./index.styles";

export default function FloatingChatButton() {
  const [bottomPosition, setBottomPosition] = useAtom(
    COMMON_LAYOUT_ATOMS.FLOATING_CHAT_BUTTON_BOTTOM_POSITION
  );

  const [unreadCount, setUnreadCount] = useAtom(
    COMMON_LAYOUT_ATOMS.CHAT_BUTTON_UNREAD_COUNT
  );

  const { isMobile } = useCheckIsMobile();

  useEffect(() => {
    setBottomPosition(
      CHAT_BUTTON_DEFAULT_BOTTOM_POSITION[isMobile ? "mobile" : "desktop"]
    );
  }, [isMobile, setBottomPosition]);

  initChatButtonUI(setUnreadCount);

  const { asPath } = useRouter();
  const isOnMyPage = asPath.includes("/mypage");
  // 데크스탑 마이페이지에서는 헤더에서 채널톡 아이콘을 표시함
  if (isOnMyPage && !isMobile) return null;

  return (
    <Styled.chatButton
      className="custom-channel-io-button"
      bottomPosition={bottomPosition}
      onClick={openChatModal}
    >
      {typeof unreadCount === "number" && unreadCount > 0 && (
        <div className="unread-count">{unreadCount}</div>
      )}

      {isMobile ? (
        <Image
          src="/assets/images/chat_button_mobile.webp"
          alt="channel-io-button"
          width={44}
          height={44}
        />
      ) : ShipdaCurrentLanguage.currentLanguage === "ko" ? (
        <Image
          src={"/assets/images/chat_button_ko.webp"}
          alt="channel-io-button"
          width={168}
          height={50}
        />
      ) : (
        <Image
          src={"/assets/images/chat_button_en.webp"}
          alt="channel-io-button"
          width={137}
          height={50}
        />
      )}
    </Styled.chatButton>
  );
}
