import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import SCHEDULE_QUERY from "@sellernote/_shared/src/queries/forwarding/SCHEDULE_QUERY";
import { HeroSectionSchedule } from "@sellernote/_shared/src/types/forwarding/schedule";

import useGetLinerInfo from "../../../../hooks/forwarding/useGetLinerInfo";

export type HeroSectionScheduleListWithCountryAndLinerInfo =
  HeroSectionSchedule & {
    polFlagUrl?: string | null;
    podFlagUrl?: string | null;
    linerName?: string | null;
    logoUrl?: string | null;
  };

export default function useGetHeroSectionRateList():
  | HeroSectionScheduleListWithCountryAndLinerInfo[]
  | undefined {
  const { data: heroSectionScheduleList } =
    SCHEDULE_QUERY.useGetHeroSectionScheduleList();

  const { data: allCountryList } = COMMON_QUERY.useGetUserCountryList({
    isForOceanTicket: false,
  });

  const { getLinerInfo } = useGetLinerInfo();

  // heroSectionScheduleList 에 country flag url, 선사 정보 추가
  const heroSectionScheduleListWithCountryAndLinerInfo =
    heroSectionScheduleList?.map((schedule) => {
      const polCountry = allCountryList?.find(
        (country) => country.name === schedule.polCountry
      );
      const podCountry = allCountryList?.find(
        (country) => country.name === schedule.podCountry
      );

      const linerInfo = getLinerInfo({
        linerId: schedule?.linerId,
        freightType: schedule?.freightType,
      });

      return {
        ...schedule,
        polFlagUrl: polCountry?.flagUrl,
        podFlagUrl: podCountry?.flagUrl,
        logoUrl: linerInfo?.logoUrl,
        linerName: linerInfo?.label,
      };
    });

  return heroSectionScheduleListWithCountryAndLinerInfo;
}
