import { useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useResetRecoilState, useSetRecoilState } from "recoil";

import {
  APP_BUILD_INFO,
  IS_UNDER_PRODUCTION,
} from "@sellernote/_shared/src/constants";
import { USER_ATOMS } from "@sellernote/_shared/src/states/common/user";
import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import withRequireAuth from "@sellernote/shipda-kr/src/hocs/withRequireAuth";
import { MY_PAGE_ATOMS } from "@sellernote/shipda-kr/src/states/forwarding/myPage";

import SelectLanguage from "../_components/SelectLanguage";
import ToggleI18nKey from "../_components/ToggleI18nKey";
import User from "../User";
import HamburgerMenu from "./HamburgerMenu";
import ShipdaLogo from "./ShipdaLogo";
import Styled from "./index.styles";

function MyPageHeader() {
  const { t } = useTranslation(["common-new"]);

  const router = useRouter();

  const setRoutingTo = useSetRecoilState(MY_PAGE_ATOMS.ROUTING_TO);

  const setLoggedIn = useSetRecoilState(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const resetUserTeamAuthorityInfo = useResetRecoilState(
    USER_ATOMS.USER_TEAM_AUTHORITY_INFO
  );

  useEffect(() => {
    const accessToken =
      window.localStorage.getItem("accessToken") ||
      window.sessionStorage.getItem("accessToken");

    if (accessToken) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
      resetUserTeamAuthorityInfo();
    }
  }, [setLoggedIn, resetUserTeamAuthorityInfo]);

  return (
    <Styled.container>
      {!IS_UNDER_PRODUCTION && APP_BUILD_INFO && (
        <div className="build-info">
          {formatDate({
            date: APP_BUILD_INFO.builtAt,
            type: "YY_MM_DD_HH_mm_ss",
          })}
        </div>
      )}

      <HamburgerMenu />

      <Link href="/">
        <a onClick={() => setRoutingTo("/")}>
          <ShipdaLogo />
        </a>
      </Link>

      <Styled.rightContainer>
        {!IS_UNDER_PRODUCTION && <ToggleI18nKey />}

        <SelectLanguage needsWhiteText isMyPage />

        <User isMyPage={true} />
      </Styled.rightContainer>
    </Styled.container>
  );
}

export default withRequireAuth(MyPageHeader);
