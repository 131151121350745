import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR, TEXT_COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

import {
  fadeInAnimation,
  fadeUpAnimation,
} from "../../../components/landing/index.styles";

const container = styled.section`
  padding: 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR.wh};

  ${mobile(css`
    padding: 48px 16px;
  `)}
`;

const content = styled.div`
  width: 1160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  ${fadeUpAnimation};
  ${fadeInAnimation};

  ${mobile(css`
    width: 100%;
    height: initial;
    gap: 32px;
  `)}
`;

const title = styled.h3`
  ${setFontStyle("Head2")};
  color: ${TEXT_COLOR.black_2};
  animation-delay: 0.2s;

  strong {
    color: ${COLOR.primary_600};
  }

  .mobile-only {
    display: none;
  }

  ${mobile(css`
    ${setFontStyle("Head5")};
    text-align: center;

    .mobile-only {
      display: initial;
    }
  `)}
`;

const imageList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .customer-list-image-1 {
    animation-delay: 0.4s;
  }

  .customer-list-image-2 {
    animation-delay: 0.5s;
  }

  .customer-list-image-3 {
    animation-delay: 0.6s;
  }

  .customer-list-image-4 {
    animation-delay: 0.7s;
  }
`;

const description = styled.p`
  ${setFontStyle("Body3")};
  color: ${TEXT_COLOR.black_3};
  animation-delay: 0.7s;

  ${mobile(css`
    ${setFontStyle("Body5")};
    animation-delay: 0.9s;
  `)}
`;

const customerCardContainer = styled.div`
  width: 1128px;
  display: flex;
  gap: 24px;

  ${mobile(
    css`
      width: 100%;
      flex-direction: column;
      gap: 16px;
    `
  )}
`;

export default {
  container,
  content,
  title,
  imageList,
  description,
  customerCardContainer,
};
