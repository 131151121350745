import { useState } from "react";
import Link from "next/link";
import Router from "next/router";
import { useRecoilValue } from "recoil";

import { FORWARDING_AUTH_ATOMS } from "@sellernote/_shared/src/states/forwarding/auth";
import { noop } from "@sellernote/_shared/src/utils/common/etc";
import { addEventToGTMDataLayer } from "@sellernote/_shared/src/utils/common/gtm";
import PlusSolidIcon from "@sellernote/_sds-v2/src/components/svgIcons/PlusSolidIcon";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";

import { QuickActionItem } from ".";
import Styled from "./index.styles";

export default function QuickActionsForMobile({
  list,
}: {
  list: QuickActionItem[];
}) {
  const loggedIn = useRecoilValue(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const [opens, setOpens] = useState(false);

  // 도입문의의 경우, 글자 수가 적어 애니메이션 시작 시, 최소로 줄어드는 사이즈의 비율을 좀 더 크게 반영.
  const mobileQuickActionItemMinScaleX = (label: string) => {
    return label.length < 5 ? 0.45 : 0.35;
  };

  const getCommonQuickActionItemProps = ({
    label,
    i,
  }: {
    label: string;
    i: number;
  }) => ({
    key: label,
    className: `${opens ? "opened-actions" : "closed-actions"}`,
    translateY: Math.abs(i - 3) * 50,
    minScaleX: mobileQuickActionItemMinScaleX(label),
  });

  return (
    <Styled.mobileContainer loggedIn={loggedIn}>
      <div
        className={`toggle-button ${opens ? "opened-actions" : ""}`}
        onClick={() => setOpens(!opens)}
      >
        <PlusSolidIcon
          color={COLOR.grayScale_800}
          width={24}
          height={24}
          className="x-mark-icon"
          onClick={noop}
        />
      </div>

      <ul className={`${opens ? "opened-actions" : "closed-actions"}`}>
        {list.map(
          ({ path, Icon, isExternalLink, label, handleClick, gtmEvent }, i) =>
            path ? (
              <Styled.mobileQuickActionItem
                type="link"
                {...getCommonQuickActionItemProps({ label, i })}
              >
                <div className="animation-wrapper">
                  <Link href={path}>
                    <a
                      target={isExternalLink ? "_blank" : "_top"}
                      rel={isExternalLink ? "noopener noreferrer" : ""}
                      onClick={() => {
                        if (!gtmEvent) {
                          return;
                        }

                        addEventToGTMDataLayer(gtmEvent);
                      }}
                    >
                      <span className="label">{label}</span>
                    </a>
                  </Link>
                </div>

                <Icon
                  color={COLOR.grayScale_800}
                  width={16}
                  height={16}
                  onClick={() => (isExternalLink ? noop : Router.push(path))}
                />
              </Styled.mobileQuickActionItem>
            ) : (
              <Styled.mobileQuickActionItem
                type="action"
                onClick={handleClick}
                {...getCommonQuickActionItemProps({ label, i })}
              >
                <div className="animation-wrapper">
                  <span className="label">{label}</span>
                </div>

                <Icon color={COLOR.grayScale_800} width={16} height={16} />
              </Styled.mobileQuickActionItem>
            )
        )}
      </ul>
    </Styled.mobileContainer>
  );
}
