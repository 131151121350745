import { useCallback, useState } from "react";
import { useRouter } from "next/router";

import FourChevronDownIcon from "@sellernote/_sds-v2/src/components/svgIcons/FourChevronDownIcon";
import FourChevronUpIcon from "@sellernote/_sds-v2/src/components/svgIcons/FourChevronUpIcon";
import GlobeIcon from "@sellernote/_sds-v2/src/components/svgIcons/GlobeIcon";

import useSelectLanguage from "../../_hooks/useSelectLanguage";
import Styled from "./index.styles";

export default function SelectLanguage({
  isMyPage,
  needsWhiteText = false,
}: {
  isMyPage: boolean;
  needsWhiteText?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const { locale } = useRouter();

  const handleSelectLanguageOpen = () => {
    setIsOpen(true);
  };

  const handleSelectLanguageClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const { handleLanguageSelect } = useSelectLanguage();

  return (
    <Styled.selectLanguage
      isOpen={isOpen}
      needsWhiteText={needsWhiteText}
      onClick={isOpen ? handleSelectLanguageClose : handleSelectLanguageOpen}
    >
      <Styled.iconAndLabel>
        <GlobeIcon width={16} height={16} />

        <span className="language-label">{locale?.toUpperCase() || "KO"}</span>
      </Styled.iconAndLabel>

      {isOpen ? (
        <>
          <FourChevronUpIcon width={16} height={16} />

          <Styled.languageList isMyPage={isMyPage}>
            <div
              className="language-item"
              onClick={() => handleLanguageSelect("ko")}
            >
              KO
            </div>
            <div
              className="language-item"
              onClick={() => handleLanguageSelect("en")}
            >
              EN
            </div>
          </Styled.languageList>
        </>
      ) : (
        <FourChevronDownIcon width={16} height={16} />
      )}
    </Styled.selectLanguage>
  );
}
