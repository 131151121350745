import React, { useState } from "react";
import Link from "next/link";

import Styled from "./index.styles";

const ArrowBlock = () => {
  return (
    <svg
      width="44"
      height="57"
      viewBox="0 0 44 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="arrow-block"
    >
      <g filter="url(#filter0_dd_2403_5651)">
        <path
          d="M22.7071 8.70711L30 16H14L21.2929 8.70711C21.6834 8.31658 22.3166 8.31658 22.7071 8.70711Z"
          fill="white"
        />
      </g>
      <rect x="4" y="16" width="37" height="41" fill="white" />
      <defs>
        <filter
          id="filter0_dd_2403_5651"
          x="0"
          y="0.414215"
          width="44"
          height="35.5858"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2403_5651"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="dilate"
            in="SourceAlpha"
            result="effect2_dropShadow_2403_5651"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2403_5651"
            result="effect2_dropShadow_2403_5651"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2403_5651"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default function MenuItem({
  label,
  path,
  children,
}: {
  label: string;
  path?: string;
  children?: React.ReactNode;
}) {
  const [isActive, setIsActive] = useState(false);

  const isExternalLink = path?.includes("http");

  return (
    <Styled.menu
      onMouseEnter={() => {
        setIsActive(true);
      }}
      onMouseLeave={() => {
        setIsActive(false);
      }}
      className="desktop-header-menu-item" // Header style 에서 Header bg가 투명해지는 경우, custom 하고 있음.
    >
      {path ? (
        <Link href={path}>
          <a
            className={`${isActive ? "active" : ""} label`}
            {...(isExternalLink && {
              target: "_blank",
              rel: "noopener noreferrer",
            })}
          >
            {label}
          </a>
        </Link>
      ) : (
        <div className={`${isActive ? "active" : ""} label`}>{label}</div>
      )}

      {children && (
        <Styled.submenu className={`${isActive ? "active" : ""} submenu`}>
          <ArrowBlock />

          {children}
        </Styled.submenu>
      )}
    </Styled.menu>
  );
}
