import styled, { css } from "styled-components";

import { mobile } from "@sellernote/_shared/src/styles/functions";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setElevationStyle } from "@sellernote/_sds-v2/src/styles/elevation";

const container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  background-color: ${COLOR.grayScale_900};
  padding: 0px 24px;
  z-index: 100;
  display: flex;
  align-items: center;

  > .build-info {
    color: gray;
    position: absolute;
    top: 0;
    right: 2px;
    font-size: 12px;

    ${mobile(css`
      font-size: 10px;
    `)}
  }

  ${mobile(css`
    height: 48px;
    padding: 0 12px;
    ${setElevationStyle("level3")};
  `)}
`;

const rightContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 28px;

  ${mobile(css`
    gap: 16px;
  `)}
`;

export default {
  container,
  rightContainer,
};
