import SvgIconWrapper from "./SvgIconWrapper";

export default function SubMenuSourcingIcon(
  props: React.SVGAttributes<SVGElement>
) {
  return (
    <SvgIconWrapper {...props}>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.66241 8.28846L12.2097 2.77635L11.2048 2.19617L1.63313 7.72234L2.66241 8.28846Z"
          fill="#D4E1F4"
        />
        <path
          d="M2.30147 18.9368V8.94368L1.27219 8.34595V19.4L10.8445 24.9265V23.8691L2.30147 18.9368Z"
          fill="#D4E1F4"
        />
        <path
          d="M0.683595 19.8911L10.9459 25.8161C11.0041 25.8497 11.0701 25.8673 11.1373 25.8673C11.2045 25.8673 11.2705 25.8497 11.3287 25.8161L15.5 23.5L15.1266 22.837L11.5391 24.8215V13.8548L21 8.37184V11.6484H21.7656V7.70963C21.7673 7.64311 21.7512 7.57735 21.719 7.51909C21.6869 7.46084 21.6398 7.41218 21.5826 7.37812L11.3245 1.45316C11.2667 1.41956 11.201 1.40186 11.1342 1.40186C11.0673 1.40186 11.0016 1.41956 10.9438 1.45316L0.682556 7.37812C0.624489 7.41177 0.576318 7.46013 0.542897 7.51833C0.509476 7.57654 0.491986 7.64252 0.492189 7.70963V19.5596C0.492191 19.6268 0.50988 19.6928 0.543479 19.751C0.577078 19.8092 0.625402 19.8575 0.683595 19.8911ZM1.25781 8.37184L10.7734 13.8548V24.8215L1.25781 19.3385V8.37184ZM6.67111 10.6132L16.1672 5.13068L20.6336 7.70925L11.1373 13.1917L6.67111 10.6132ZM11.1373 2.22672L15.4015 4.68864L5.90549 10.1712L1.64134 7.70925L11.1373 2.22672Z"
          fill="#475FB0"
        />
        <g clipPath="url(#clip0_16334_31)">
          <path
            d="M20.8198 21.0401C20.0795 21.139 19.3284 20.9646 18.7073 20.5498C18.0862 20.1349 17.6375 19.5079 17.4453 18.7861C17.2531 18.0643 17.3305 17.2972 17.663 16.6284C17.9955 15.9596 18.5604 15.4348 19.2518 15.1524C18.8194 15.2101 18.4042 15.3594 18.0339 15.5902C17.6637 15.821 17.3468 16.128 17.1045 16.4908C16.8622 16.8537 16.6999 17.2639 16.6286 17.6944C16.5572 18.1248 16.5784 18.5655 16.6906 18.9871C16.8029 19.4087 17.0037 19.8015 17.2797 20.1395C17.5557 20.4774 17.9005 20.7527 18.2912 20.9469C18.6819 21.1411 19.1095 21.2499 19.5455 21.266C19.9815 21.282 20.4159 21.205 20.8198 21.0401Z"
            fill="#D4E1F4"
          />
          <path
            d="M26.8864 25.8752L26.8808 25.8807C26.7582 26.0031 26.592 26.0718 26.4187 26.0718C26.2454 26.0718 26.0793 26.0031 25.9566 25.8808L26.8864 25.8752ZM26.8864 25.8752C27.0052 25.7531 27.0718 25.5894 27.0718 25.4188C27.0718 25.2455 27.0031 25.0793 26.8807 24.9567L26.8864 25.8752ZM24.135 21.3396L24.135 21.3395C24.0772 21.2818 23.9989 21.2493 23.9171 21.2493C23.8354 21.2493 23.7571 21.2818 23.6993 21.3395C23.6993 21.3395 23.6993 21.3395 23.6993 21.3395L23.5342 21.5046L23.0112 20.9816L22.9287 20.8991L22.8462 20.9816L22.5755 21.2523L22.493 21.3348L22.5755 21.4173L23.0986 21.9403L22.9403 22.0986L22.4172 21.5756L22.3347 21.4931L22.2522 21.5756L21.9815 21.8462L21.899 21.9287L21.9815 22.0112L22.5046 22.5343L22.3395 22.6994C22.3395 22.6994 22.3395 22.6994 22.3395 22.6994C22.2817 22.7571 22.2493 22.8355 22.2493 22.9172C22.2493 22.9989 22.2817 23.0773 22.3395 23.135L25.5208 26.3166L25.5208 26.3166C25.7589 26.5547 26.0819 26.6885 26.4186 26.6885C26.7554 26.6885 27.0783 26.5547 27.3164 26.3166C27.5546 26.0785 27.6883 25.7555 27.6883 25.4188C27.6883 25.082 27.5546 24.7591 27.3164 24.521L24.135 21.3396ZM25.9565 25.8807L23.9236 23.8477L24.8477 22.9236L26.8807 24.9566L25.9565 25.8807ZM22.9929 22.9173L23.9171 21.9931L24.412 22.4879L23.4879 23.4121L22.9929 22.9173Z"
            fill="#54C3D6"
            stroke="#54C3D6"
            strokeWidth="0.233333"
          />
          <circle
            cx="19.2454"
            cy="18.2455"
            r="4.59751"
            stroke="#475FB0"
            strokeWidth="0.8"
          />
          <path
            d="M19.2454 15.2593C17.5962 15.2593 16.2592 16.5963 16.2592 18.2455C16.2592 19.8948 17.5962 21.2318 19.2454 21.2318C20.8947 21.2318 22.2317 19.8948 22.2317 18.2455C22.2317 16.9744 21.4375 15.8888 20.3183 15.4578"
            stroke="#475FB0"
            strokeWidth="0.8"
            strokeLinecap="round"
          />
          <path
            d="M17.5681 17.3824C17.4528 17.5475 17.4436 17.5782 17.4528 17.7165"
            stroke="#475FB0"
            strokeWidth="0.8"
            strokeLinecap="round"
          />
          <path
            d="M17.4904 18.7253C17.5716 18.9096 17.7985 19.5144 18.5822 19.9522"
            stroke="#475FB0"
            strokeWidth="0.8"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_16334_31">
            <rect
              width="14"
              height="14"
              fill="white"
              transform="translate(14 13)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIconWrapper>
  );
}
